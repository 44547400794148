<template lang="pug">
.system-check-card
  .system-check-card-title.has-line
    span Connection
  .system-check-card-contents
    .system-check-description
      span(v-html="htmlMessage")
      Status
  BaseTip(:tipMessage="htmlTip")
</template>
<script lang="ts">
import { Component } from "vue-property-decorator";
import BaseStep from "./BaseStep.vue";
import Download from "@/utils/download";
import { DateTime } from "luxon";
import BaseTip from "@/components/SystemCheck/BaseTip.vue";

@Component({
  components: { BaseTip },
})
export default class Connection extends BaseStep {
  private mbs = "Calculating...";
  private minMbs = 5;

  async mounted(): Promise<void> {
    const startTime = DateTime.now().toMillis();
    const res = await Download.downloadLearnerGuide(false);
    const endTime = DateTime.now().toMillis();
    const duration = (endTime - startTime) / 1000;
    const bitsLoaded = res.fileSize * 8;
    const bps = bitsLoaded / duration;
    const kbps = bps / 1024;
    const mbps = kbps / 1024;
    this.mbs = `${mbps.toFixed(2)}Mb/s`;
    if (mbps > this.minMbs) this.passActive();
    else this.failActive(this.message);
  }

  get htmlMessage(): string {
    let message = "We recommend a minimum bandwidth of around 5–10 Mb/s.<br>";
    message += `Your speed measured ${this.mbs}.`;
    return message;
  }

  get htmlTip(): string {
    return `Most often, reduction in internet speed is due to other activities on the network. On a home network, gaming and movie services can greatly impact internet speed. If you are using a VPN, consider disabling it for the session.`;
  }
  get message(): string {
    return this.failedMessage([this.htmlMessage, this.htmlTip]);
  }
}
</script>
<style lang="scss"></style>
