<template lang="pug">
.system-check-card
  .system-check-card-title.has-line
    span Camera
  .system-check-card-contents
    .system-check-description
      .flex
        #loader-place-holder(v-show="loading")
          .icon-loader.spin
        #system-check-camera(v-show="!loading")
        #system-check-camera-options
          span Do you see your video?
          SelectBox(
            displayExpr="label",
            placeholder="...Video Devices Loading",
            valueExpr="deviceId",
            :value="selectedDevice",
            :dataSource="devices",
            :returnExpr="['deviceId']",
            dataName="camera-selector",
            @valueChanged="onVideoDeviceChange"
          )
          .confirmation-buttons
            Button(text="Yes", primary, small, dataName="camera-button-yes", @click="passActive")
            Button.list(text="No", primary, small, dataName="camera-button-no", @click="failActive(message)")
      Session(v-if="!deviceWarning", :ref="sessionRef", :isSysCheck="true")
      Status
      #camera-warning-msg(v-if="deviceWarning || isActiveFailed")
        span(v-html="htmlMessage")
      BaseTip(tip-list="true", :tip-list="tips")
</template>
<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import { LocalStorageFields } from "@/types/base";
import BaseVonageStep from "./BaseVonageStep.vue";
import BaseTip from "@/components/SystemCheck/BaseTip.vue";

@Component({
  components: { BaseTip },
})
export default class Camera extends BaseVonageStep {
  private tips = [
    `Be sure your camera is connected, turned on, and enabled in your browser settings.`,
    `Best practice is to close your browser completely and open a new browser window before logging in.`,
    `If you are simultaneously using other applications (such as Zoom, Teams, etc.) that use your camera, you may experience issues.`,
  ];
  async mounted(): Promise<void> {
    await this.populatedDevices("videoInput");
    const storedDevice = localStorage.getItem(LocalStorageFields.CAMERA_ID);
    if (storedDevice) this.deviceId = storedDevice;
  }

  get tipsResult(): string {
    let resultTipsList = "";
    this.tips.forEach((tip) => {
      resultTipsList += "• ";
      resultTipsList += tip;
      resultTipsList += "<br/>";
    });
    return resultTipsList;
  }
  get htmlMessage(): string {
    return `We encourage all learners to have a camera to maximize their training experience especially during breakout activities.`;
  }

  get message(): string {
    return this.failedMessage([this.htmlMessage, this.tipsResult]);
  }
  @Watch("publisher")
  publisherChange(): void {
    this.loading = false;
    this.publisher?.publishVideo(true);
    this.publisher?.publishAudio(false);
  }

  async onVideoDeviceChange(selectedItem: { deviceId: string }): Promise<void> {
    this.todoActive();
    this.setRootState({ selectedVideoId: selectedItem.deviceId });
    localStorage.setItem(LocalStorageFields.CAMERA_ID, selectedItem.deviceId);
    this.refreshPublisherSettings();
  }
}
</script>
<style lang="scss" scoped>
#system-check-camera,
#loader-place-holder {
  width: 500px;
  min-width: 500px;
  max-width: 500px;
  margin-bottom: 35px;
}

#loader-place-holder {
  text-align: center;
  padding-right: 250px;
}
#system-check-camera-options {
  margin-left: -250px;
  display: flex;
  flex-direction: column;
}
#camera-warning-msg span {
  line-height: 1.5rem;
}
#system-check-camera-options span {
  margin-bottom: 0.5rem;
}
</style>
