export interface SystemCheckState {
  steps: SystemCheckStep[];
  activeIdx: number;
}

export interface SystemCheckStep {
  name: string;
  component: string;
  state: StepState;
  error: string;
  required: boolean;
}

export enum StepState {
  TODO,
  FAIL,
  SUCCESS,
}

//FUNCTIONS
export interface setActiveIdx {
  (idx: number): void;
}

export interface FailIdx {
  (update: { idx: number; error: string }): void;
}
export interface PassIdx {
  (idx: number): void;
}
export interface TodoIdx {
  (idx: number): void;
}
export interface GetFailedSteps {
  (): SystemCheckStep[];
}

export interface SetSystemCheckState {
  (changes: Partial<SystemCheckState>): void;
}
