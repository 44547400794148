import { RootState } from "@/types";
import { StepState, SystemCheckState, SystemCheckStep } from "@/types/SystemCheck";
import { Module } from "vuex";

const SystemCheckModule: Module<SystemCheckState, RootState> = {
  namespaced: true,
  state(): SystemCheckState {
    return {
      steps: [
        {
          name: "System Check",
          component: "Landing",
          state: StepState.SUCCESS,
          error: "",
          required: false,
        },
        {
          name: "Browser",
          component: "Browser",
          state: StepState.TODO,
          error: "",
          required: false,
        },
        {
          name: "Resolution",
          component: "Resolution",
          state: StepState.TODO,
          error: "",
          required: false,
        },
        {
          name: "Connection",
          component: "Connection",
          state: StepState.TODO,
          error: "",
          required: false,
        },
        {
          name: "Camera",
          component: "Camera",
          state: StepState.TODO,
          error: "",
          required: false,
        },
        {
          name: "Microphone",
          component: "Microphone",
          state: StepState.TODO,
          error: "",
          required: true,
        },
        {
          name: "Speaker",
          component: "Speaker",
          state: StepState.TODO,
          error: "",
          required: true,
        },
        {
          name: "Media Playback",
          component: "MediaPlayback",
          state: StepState.TODO,
          error: "",
          required: false,
        },
        {
          name: "Presentation Slides",
          component: "PresentationSlides",
          state: StepState.TODO,
          error: "",
          required: false,
        },
        {
          name: "Results Report",
          component: "ResultsReport",
          state: StepState.TODO,
          error: "",
          required: false,
        },
      ],
      activeIdx: 0,
    };
  },
  mutations: {
    setSystemCheckState(state: SystemCheckState, changes: Partial<SystemCheckState>): void {
      Object.assign(state, changes);
    },
    setActiveIdx(state: SystemCheckState, idx: number): void {
      state.activeIdx = idx;
    },
    nextStep(state: SystemCheckState): void {
      state.activeIdx++;
    },
    previousStep(state: SystemCheckState): void {
      state.activeIdx--;
    },
    updateIdx(state: SystemCheckState, update: { stepState: StepState; idx: number; error: string }) {
      state.steps[update.idx].state = update.stepState;
      state.steps[update.idx].error = update.error;
    },
    retake(state: SystemCheckState): void {
      state.activeIdx = 0;
      state.steps.forEach((step, idx) => {
        if (idx == 0) return;
        step.state = StepState.TODO;
        step.error = "";
      });
    },
    retakeStep(state: SystemCheckState, name: string): void {
      const idx = state.steps.findIndex((e) => e.name === name);
      if (idx != -1) {
        state.steps[idx].state = StepState.TODO;
        state.activeIdx = idx;
      }
    },
  },
  getters: {
    activeStep(state: SystemCheckState): SystemCheckStep {
      return state.steps[state.activeIdx];
    },
    isActiveFailed(_, getters): boolean {
      return getters.activeStep.state === StepState.FAIL;
    },
    isNextAvailable(state: SystemCheckState): boolean {
      // Report Step
      if (state.steps.length - 1 === state.activeIdx) return false;
      // Last step prior to report, requires all others to be complete
      if (state.steps.length - 2 === state.activeIdx) {
        const completed = state.steps.filter((e) => e.state !== StepState.TODO);
        return completed.length == state.steps.length - 1;
      }
      return true;
    },
    isFirstStep(state: SystemCheckState): boolean {
      return state.activeIdx == 0;
    },
    isLastStep(state: SystemCheckState): boolean {
      return state.steps.length - 1 == state.activeIdx;
    },
    getFailedSteps(state: SystemCheckState): SystemCheckStep[] {
      return state.steps.filter((e) => e.state === StepState.FAIL);
    },
    getStepByName(state: SystemCheckState): (name: string) => SystemCheckStep | undefined {
      return (name: string) => state.steps.find((e) => e.name === name);
    },
    isStepRequired(state: SystemCheckState): (name: string) => boolean {
      return (name: string) => {
        const idx = state.steps.findIndex((e) => e.name === name);
        if (idx == -1) return false;
        return state.steps[idx].required;
      };
    },
    hasCautionSteps(state: SystemCheckState): boolean {
      return state.steps.filter((e) => !e.required && e.state === StepState.FAIL).length > 0;
    },
    hasErrorSteps(state: SystemCheckState): boolean {
      return state.steps.filter((e) => e.required && e.state === StepState.FAIL).length > 0;
    },
  },
  actions: {
    failIdx({ commit }, update: { idx: number; error: string }): void {
      commit("updateIdx", { stepState: StepState.FAIL, idx: update.idx, error: update.error });
    },
    passIdx({ commit }, idx: number): void {
      commit("updateIdx", { stepState: StepState.SUCCESS, idx, error: "" });
    },
    todoIdx({ commit }, idx: number): void {
      commit("updateIdx", { stepState: StepState.TODO, idx, error: "" });
    },
  },
};
export default SystemCheckModule;
