<template lang="pug">
.stepper-footer
  .stepper-buttons
    .cancel-button-container
      #cancel-system-check-button.button-with-confirm
        Button(
          v-if="isRegistration && !unsafeContinue",
          :text="cancelButtonText",
          :ghost="!cancelling",
          :secondary="cancelling",
          small,
          dataName="cancel-button",
          @click="cancelClick"
        )
    Button(
      v-show="!isFirstStep && !cancelling",
      text="previous",
      icon="chevron-left",
      :class="{ 'visibility-hidden': unsafeContinue }",
      secondary,
      small,
      dataName="previous-step-button",
      @click="previousStep"
    )
    Button(
      v-show="isNextAvailable && !cancelling",
      :text="nextText",
      :class="nextClass",
      small,
      dataName="next-step-button",
      @click="nextStep"
    )
    #complete-system-check-button.button-with-confirm
      Button(
        v-if="showCompleteButton && !cancelling",
        :text="completeButtonText",
        :class="completeButtonClass",
        dataName="complete-button",
        small,
        @click="completeAction"
      )
    Button(
      v-if="lastAndFailing && !cancelling && !unsafeContinue",
      text="Retake",
      primary,
      small,
      dataName="retake-button",
      @click="retake"
    )
  Confirminator(
    v-if="cancelling",
    position="above",
    contentWidth="200px",
    elementId="cancel-system-check-button",
    buttonText="End Now",
    bodyText="Your course registration will not be finalized until you complete the system check.",
    @cancelled="cancelling = false",
    @confirmed="toVapor"
  )
  Confirminator(
    v-if="unsafeContinue",
    position="above",
    contentWidth="150px",
    elementId="complete-system-check-button",
    buttonText="I Understand",
    bodyText="If you move ahead without passing the system checkpoints your platform experience could fail.",
    @cancelled="unsafeContinue = false",
    @confirmed="completeRegistration"
  )
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { BaseButton as Button, EventContentModel } from "@cruciallearning/puddle";
import { Confirminator, Icon } from "@cruciallearning/puddle/components";
import { mapGetters, mapMutations, mapState } from "vuex";
import config from "@/config";
import { SystemCheckStep } from "@/types/SystemCheck";
@Component({
  components: {
    Button,
    Confirminator,
    Icon,
  },
  methods: {
    ...mapMutations("SystemCheckModule", ["nextStep", "previousStep", "retake"]),
  },
  computed: {
    ...mapState("EventModule", ["event"]),
    ...mapGetters("SystemCheckModule", ["isNextAvailable", "isFirstStep", "isLastStep", "getFailedSteps"]),
  },
})
export default class StepFooter extends Vue {
  private readonly isFirstStep!: boolean;
  private readonly isLastStep!: boolean;
  private readonly getFailedSteps!: SystemCheckStep[];
  private readonly event!: EventContentModel;

  private cancelling = false;
  private unsafeContinue = false;

  get lastAndFailing(): boolean {
    return this.isLastStep && this.hasFailures;
  }

  get isFailingRequired(): boolean {
    return this.getFailedSteps.filter((e) => e.required).length > 0;
  }

  get hasFailures(): boolean {
    return this.getFailedSteps.length != 0;
  }

  get completeButtonText(): string {
    if (!this.isRegistration) return "Done";
    if (this.hasFailures) {
      if (this.unsafeContinue) {
        return "Return";
      } else {
        return "Proceed Anyway";
      }
    }
    return "Complete Registration";
  }

  get isRegistration(): boolean {
    return this.event.pendingCode != null;
  }

  get showCompleteButton(): boolean {
    if (!this.isLastStep) return false;
    if (!this.isRegistration) return true;
    return !this.isFailingRequired;
  }

  get completeButtonClass(): string {
    let classes = this.hasFailures ? "secondary" : "primary";
    classes += " wide";
    return classes;
  }

  get nextText(): string {
    if (this.isFirstStep) return "Begin";
    return "Next";
  }

  get nextClass(): string {
    if (this.isFirstStep) return "primary";
    return "secondary";
  }

  completeAction(): void {
    if (!this.isRegistration) this.toVapor();
    else {
      if (this.hasFailures) {
        this.unsafeContinue = !this.unsafeContinue;
      } else {
        this.completeRegistration();
      }
    }
  }

  completeRegistration(): void {
    const errors: { error: string; step: string }[] = this.getFailedSteps.map((e) => ({
      error: e.error,
      step: e.name,
    }));
    this.$api.registration.completeRegistration(config.backendUrl, this.event.id, errors).then((event) => {
      if (event) {
        const eventUrl = `${config.vaporUrl}/event/${event.id}/1`;
        window.open(eventUrl, "_self");
      } else {
        console.error("Registration was not able to be completed!");
      }
    });
  }

  get cancelButtonText(): string {
    return this.cancelling ? "Return" : "Cancel System Check";
  }

  toVapor(): void {
    window.open(config.vaporUrl, "_self");
  }

  confirmCancelled(): void {
    this.cancelling = false;
  }
  cancelClick(): void {
    this.cancelling = !this.cancelling;
  }
}
</script>
<style lang="scss" scoped>
.stepper-footer {
  bottom: 0;
  width: 100%;
  background-color: white;
  height: 68px;
  border: 2px solid var(--gray-20);
}
.stepper-buttons {
  height: 100%;
  display: flex;
  align-items: center;
}
.stepper-buttons .button {
  margin-right: 1rem;
  min-width: 150px;
}
.error-buttons {
  display: contents;
}
.confirmation-title {
  text-transform: capitalize;
  margin-left: 0.5rem;
}
.cancel-button-container {
  width: 376px;
  margin-left: 24px;
}
// outer button class required to maintain id through
// reactive button changes
.button-with-confirm {
  height: fit-content;
  width: fit-content;
}
.cancel-button-container .button {
  margin-right: 0;
  min-width: 200px !important;
  max-width: 200px !important;
  // must be above confirm dialog
  z-index: 210;
  position: relative;
}
#complete-system-check-button {
  margin-right: 1rem;
  z-index: 210;
}
.visibility-hidden {
  &.button {
    transition-duration: 0ms;
  }
  visibility: hidden;
}
</style>
