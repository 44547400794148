<template lang="pug">
.system-check-card
  .system-check-card-title.has-line
    span Browser
  .system-check-card-contents
    .system-check-description
      span(v-html="htmlMessage")
      Status
  BaseTip(:tip-message="htmlTip")
</template>
<script lang="ts">
import { Component } from "vue-property-decorator";
import { BotInfo, BrowserInfo, detect, NodeInfo, ReactNativeInfo, SearchBotDeviceInfo } from "detect-browser";
import BaseStep from "./BaseStep.vue";
import BaseTip from "@/components/SystemCheck/BaseTip.vue";

@Component({
  components: { BaseTip },
})
export default class Browser extends BaseStep {
  private browser: BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | ReactNativeInfo | null = null;
  private error = "We are unable to determine what browser you are using";

  mounted(): void {
    this.browser = detect();
    const validBrowsers = ["chrome", "edge-chromium"];
    const allowed = validBrowsers.find((e) => e === this.browser?.name.toLowerCase());
    if (allowed) this.passActive();
    else this.failActive(this.message);
  }

  get browserMessage(): string {
    if (this.browser) {
      return `${this.titleCase(this.browser.name)} ${this.browser.version}`;
    }
    return this.error;
  }

  get htmlMessage(): string {
    return `We recommend using Chrome or Edge for the best experience. <br> You are using ${this.browserMessage}.`;
  }
  get htmlTip(): string {
    return `To ensure the best experience, change to Chrome or Edge.`;
  }
  get message(): string {
    return this.failedMessage([this.htmlMessage, this.htmlTip]);
  }

  titleCase(name: string) {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }
}
</script>
