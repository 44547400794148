import { Module } from "vuex";
import { sockets } from "@/services/socket_api";
import { RootState } from "@/types";
import { VideoOverlay, VideoPayload, VideoState } from "@/types/video";

const VideoModule: Module<VideoState, RootState> = {
  namespaced: true,
  state(): VideoState {
    return {
      firstActionUserId: "",
      lastActionUserId: "",
      overlay: true,
      playing: false,
      position: -1,
    };
  },
  mutations: {
    setVideoState(state: VideoState, videoStateOptions: Partial<VideoState>) {
      Object.assign(state, videoStateOptions);
    },
  },
  actions: {
    endVideo({ commit }): void {
      commit("setVideoState", { playing: false });
    },
    overlay({ commit, state }, overlay: VideoOverlay): void {
      const videoState = {
        overlay,
        playing: false,
        position: -1,
      };
      if (overlay === VideoOverlay.CLOSE) {
        videoState.playing = true;
        videoState.position = state.position;
      }
      commit("setVideoState", videoState);
    },
    playVideo({ commit, state }, payload: VideoPayload["body"]): void {
      const videoState: VideoState = {
        overlay: false,
        playing: true,
        position: payload.position,
        lastActionUserId: payload.senderId,
        firstActionUserId: state.firstActionUserId === "" ? payload.senderId : state.firstActionUserId,
      };
      commit("setVideoState", videoState);
    },
    pauseVideo({ commit }, senderId: string): void {
      commit("setVideoState", { lastActionUserId: senderId, playing: false });
    },
    playVideoBroadcast(_, position: number) {
      sockets.video.play(position);
    },
    pauseVideoBroadcast() {
      sockets.video.pause();
    },
    closeOverlay(): void {
      sockets.video.overlay(VideoOverlay.CLOSE);
    },
    resetOverlay(): void {
      sockets.video.overlay(VideoOverlay.OPEN);
    },
    resetVideo({ commit }): void {
      const videoState = {
        firstActionUserId: "",
        lastActionUserId: "",
        overlay: true,
        playing: false,
        position: -1,
      };
      commit("setVideoState", videoState);
    },
  },
};

export default VideoModule;
