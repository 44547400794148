<template lang="pug">
.system-check-card
  .system-check-card-title.has-line
    span Speaker
  .system-check-card-contents
    .system-check-description
      Button.button(@click="testSpeaker", small, secondary, text="Test Speaker")
      SelectBox.select-box(
        displayExpr="label",
        placeholder="...Speaker Devices Loading",
        :value="outputDeviceId",
        valueExpr="deviceId",
        :dataSource="outputDevices",
        dataName="speaker-selector",
        @valueChanged="onSpeakerDeviceChange"
      )
    .volume-slider
      p.text Output Volume
      Icon.low-volume(value="volume-1", @click="volumeIconClick('volume-1')")
      DxSlider.slider(:min="0", :max="100", :value="volume", :label="label", @valueChanged="volumeChanged")
      Icon.high-volume(value="volume-2", @click="volumeIconClick('volume-2')")
    strong.pass-fail(v-if="isLoaded", :style="`color: ${passFailColor}`") {{ passFail }}
    .description(v-if="this.isActiveFailed && isLoaded")
      strong You won't be able to join the session without speakers or headphones.
      BaseTip(:tip-message="htmlTip")
</template>
<style scoped lang="scss"></style>
<script lang="ts">
import { Component } from "vue-property-decorator";
import BaseVonageStep from "@/components/SystemCheck/steps/BaseVonageStep.vue";
import OT from "@opentok/client";
import { LocalStorageFields } from "@/types/base";
import DevExpress from "devextreme";
import ExcelDataGridCell = DevExpress.excelExporter.ExcelDataGridCell;
import { Icon } from "@cruciallearning/puddle/components";
import BaseTip from "@/components/SystemCheck/BaseTip.vue";

@Component({
  components: { BaseTip, Icon },
})
export default class Speaker extends BaseVonageStep {
  label = { visible: true, position: "top" };
  tooltip = { enabled: true, showMode: "always", position: "bottom" };
  private volume = 50;
  private audio = new Audio();
  private outputDeviceId: string | null = null;
  protected outputDevices: OT.AudioOutputDevice[] = [];
  private isLoaded = false;
  async mounted(): Promise<void> {
    this.outputDevices = await this.populateOutputDevices();
    if (this.outputDevices) {
      this.outputDeviceId = "default";
      const storedDevice = localStorage.getItem(LocalStorageFields.SPEAKER_ID);
      if (storedDevice) this.outputDeviceId = storedDevice;
      this.passActive();
    } else {
      this.failActive(this.message);
    }
    this.audio = new Audio(await require(`@/assets/audio/apron-time.mp3`));
    this.isLoaded = true;
    this.failActive(this.message);
  }
  async onSpeakerDeviceChange(selectedItem: { deviceId: string }): Promise<void> {
    this.todoActive();
    await OT.setAudioOutputDevice(selectedItem.deviceId);
    this.outputDeviceId = selectedItem.deviceId;
    this.setRootState({ selectedSpeakerId: selectedItem.deviceId });
    localStorage.setItem(LocalStorageFields.SPEAKER_ID, selectedItem.deviceId);
    if (this.outputDevices) this.passActive();
    else this.failActive(this.message);
  }
  testSpeaker(): void {
    // volume must be between 0 & 1 so divide by 100 to get the %
    this.audio.volume = this.volume / 100;
    this.audio.play();
  }
  volumeChanged(e: ExcelDataGridCell): void {
    this.volume = e.value;
  }
  volumeIconClick(iconName: string): void {
    if (iconName == "volume-1") this.volume = 0;
    else if (iconName == "volume-2") this.volume = 100;
  }
  async populateOutputDevices(): Promise<OT.AudioOutputDevice[]> {
    return await OT.getAudioOutputDevices();
  }
  get passFail(): string {
    return this.isActiveFailed ? "Fail" : "Pass";
  }
  get passFailColor(): string {
    return this.isActiveFailed ? "red" : "green";
  }
  get htmlMessage(): string {
    return `We are unable to detect audio output like a speaker or headset.`;
  }
  get htmlTip(): string {
    return `Be sure you have a speaker or headset connected.`;
  }
  get message(): string {
    return this.failedMessage([this.htmlMessage, this.htmlTip]);
  }
}
</script>
<style lang="css" scoped>
::v-deep .dx-slider-handle {
  border-radius: 100%;
  height: 15px;
  width: 15px;
}
.volume-slider {
  display: flex;
  align-items: baseline;
  padding-top: 50px;
  padding-left: 20px;
  align-self: center;
}
.slider {
  width: 55%;
  padding-left: 10px;
}
.text {
  padding-right: 30px;
  align-items: center;
}
.low-volume {
  align-self: center;
  padding-left: 20px;
}
.high-volume {
  padding-left: 10px;
  align-self: center;
}
.select-box {
  width: 60%;
  display: inline-block;
}
.button {
  display: inline-block;
}
.system-check-card-contents {
  margin-top: 40px;
}
.system-check-description {
  align-items: center;
  display: inline-block;
}
.description {
  padding-left: 18px;
  font-size: 0.85rem;
}
.pass-fail {
  padding-left: 20px;
  font-size: 0.85rem;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Flama, serif;
}
</style>
