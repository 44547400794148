<template lang="pug">
.active-system-check
  component(:is="activeStep.component")
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Header from "@/components/Header/SimpleHeader.vue";
import Stepper from "@/components/SystemCheck/StepsSideBar.vue";
import Landing from "@/components/SystemCheck/steps/Landing.vue";
import Resolution from "@/components/SystemCheck/steps/Resolution.vue";
import { mapGetters } from "vuex";
import Browser from "./steps/Browser.vue";
import Connection from "./steps/Connection.vue";
import Camera from "./steps/Camera.vue";
import Microphone from "./steps/Microphone.vue";
import MediaPlayback from "./steps/MediaPlayback.vue";
import PresentationSlides from "./steps/PresentationSlides.vue";
import ResultsReport from "./steps/ResultsReport.vue";
import Speaker from "@/components/SystemCheck/steps/Speaker.vue";
@Component({
  components: {
    Header,
    Stepper,
    Browser,
    Landing,
    Resolution,
    Connection,
    Camera,
    Microphone,
    Speaker,
    MediaPlayback,
    PresentationSlides,
    ResultsReport,
  },
  computed: {
    ...mapGetters("SystemCheckModule", ["activeStep"]),
  },
})
export default class SelectedStep extends Vue {}
</script>
<style scoped lang="scss">
.active-system-check {
  width: 100%;
  padding: 25px 25% 0px 50px;
  overflow: auto;
}
::v-deep .dx-dropdowneditor {
  background-color: white !important;
}

::v-deep .confirmation-buttons {
  margin: 1rem 0;
  display: flex;
}
::v-deep .button {
  margin: 0 1rem;
}
::v-deep #warning-message {
  margin-top: 0.5rem;
}
::v-deep .required-message {
  margin-top: 1rem;
  font-size: 0.85rem;
  span {
    font-size: inherit;
  }
}
</style>
