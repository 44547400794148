<template lang="pug">
.sidebar
  nav.steps
    ul.list-unstyled
      li(
        v-for="(step, idx) in steps",
        :class="[activeClass(step), disabledClass(idx)]",
        @click="setActiveIdx(idx)",
        :data-name="dataName(step)"
      ) 
        Icon(:value="iconProps(step).value", :color="iconProps(step).color")
        span.step-name(:class="[requiredClass(step)]") {{ step.name }}
</template>
<script lang="ts">
import { StepState, SystemCheckStep } from "@/types/SystemCheck";
import { Icon } from "@cruciallearning/puddle/components";
import { Vue, Component } from "vue-property-decorator";
import { mapGetters, mapMutations, mapState } from "vuex";
@Component({
  components: {
    Icon,
  },
  computed: {
    ...mapState(["isConnected"]),
    ...mapState("SystemCheckModule", ["steps", "activeIdx"]),
    ...mapGetters("SystemCheckModule", ["isStepRequired"]),
  },
  methods: {
    ...mapMutations("SystemCheckModule", ["setActiveIdx"]),
  },
})
export default class StepsSideBar extends Vue {
  private readonly steps!: SystemCheckStep[];
  private readonly activeIdx!: number;
  private readonly isConnected!: boolean;
  private readonly isStepRequired!: (name: string) => boolean;
  get iconProps(): (step: SystemCheckStep) => { value: string; color: string } {
    return (step: SystemCheckStep) => {
      switch (step.state) {
        case StepState.SUCCESS:
          return { color: "green", value: "check-circle" };
        case StepState.FAIL:
          if (this.isStepRequired(step.name)) {
            return { color: "red", value: "alert-octagon" };
          } else {
            return { color: "#F75301", value: "alert-triangle" };
          }
        default:
          return { color: "gray", value: "alert-circle" };
      }
    };
  }

  get activeClass(): (step: SystemCheckStep) => string {
    return (step: SystemCheckStep) => (this.isActive(step) ? "active-step" : "");
  }

  get requiredClass(): (step: SystemCheckStep) => string {
    return (step: SystemCheckStep) => (step.required ? "required-step" : "");
  }

  get dataName(): (step: SystemCheckStep) => string {
    return (step: SystemCheckStep) => `step-selector-${step.name.toLowerCase().replace(" ", "-")}`;
  }

  get disabledClass(): (stepIdx: number) => string {
    return (stepIdx: number) => {
      if (!this.isConnected) return "disabled";
      if (stepIdx == this.steps.length - 1) {
        const completedCount = this.steps.filter((e) => e.state !== StepState.TODO);
        const showReport = completedCount.length == this.steps.length - 1;
        if (!showReport) return "disabled";
      }
      return "";
    };
  }

  get isActive(): (step: SystemCheckStep) => boolean {
    return (step: SystemCheckStep) => {
      return this.steps.findIndex((b) => b.name === step.name) === this.activeIdx;
    };
  }
}
</script>
<style lang="scss" scoped>
.sidebar {
  height: 100%;
  width: 350px;
  max-width: 350px;
  min-width: 350px;
  overflow: auto scroll;
}
.steps {
  background-color: inherit;
  margin-left: 0;
  margin-right: 0;
  line-height: 0.7rem;
  height: calc(100% - 150px - 4rem);
}
.step-name {
  text-transform: uppercase;
  margin-left: 7px;
}

.active-step {
  background-color: white;
}

.steps li:hover {
  background-color: var(--gray-20);
  cursor: pointer;
}

li span {
  font-family: "flama_condensed", sans-serif;
  font-size: 1.25rem !important;
}
</style>
