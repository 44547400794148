import { render, staticRenderFns } from "./Resolution.vue?vue&type=template&id=6665fd87&scoped=true&lang=pug"
import script from "./Resolution.vue?vue&type=script&lang=ts"
export * from "./Resolution.vue?vue&type=script&lang=ts"
import style0 from "./Resolution.vue?vue&type=style&index=0&id=6665fd87&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6665fd87",
  null
  
)

export default component.exports